(function () {
  'use strict';

  angular
    .module('learning.index')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.learning.index', {
        url: '/index',
        templateUrl: 'dashboard/learning/index/index.tpl.html',
        controller: 'LearningIndexCtrl',
        controllerAs: 'indexLearning',
        roles: ['superadmin', 'admin']
      });
  }
}());
